<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="setting_paymethod">
                <form-radio-group
                    class="setting_paymethod_radios"
                    :class="{'setting_paymethod_radios--mb': paymethod !== 'usdt'}"
                    :errors="validationMessage($v.paymethod)"
                    @change.native="$v.paymethod.$touch()"
                    @blur.native="$v.paymethod.$touch()"
                >
                    <!-- paypal -->
                    <div
                        id="paymentForm"
                        class="checkout__payment checkout-paypal"
                    >
                        <form-input-radio
                            v-model="$v.paymethod.$model"
                            :field.sync="paymethod"
                            :text="'PayPal'"
                            :data="'paypal'"
                            :is-checked="paymethod === 'paypal'"
                            name="paypal"
                        />
                    </div>

                    <!-- usdt -->
                    <div class="checkout__payment checkout-credit">
                        <form-input-radio
                            v-model="$v.paymethod.$model"
                            :field.sync="paymethod"
                            :data="'usdt'"
                            :is-checked="paymethod === 'usdt'"
                            :text="'Payoneer'"
                            name="payoneer"
                        />
                    </div>
                </form-radio-group>

                <div
                    v-if="paymethod===`paypal`"
                    class="setting_paymethod_paypal-email"
                >
                    <form-input
                        v-model.trim="$v.email1.$model"
                        legend="Email address"
                        prepend-icon="EmailOutlineIcon"
                        type="text"
                        name="email"
                        :field.sync="email1"
                        :errors="validationMessage($v.email1)"
                        :is-valid="$v.email1.$dirty && !$v.email1.$anyError"
                        @input.native="$v.email1.$touch()"
                        @blur.native="$v.email1.$touch()"
                    />
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/profile/profile-payment-method/rules'
import { formMessages } from '@/validation/profile/profile-payment-method/messages'

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    ACTION_CLIENT_UPDATE_PAYMETHOD
} from '@/store/modules/client/action-types';
import {
    mapClientFields
} from '@/store/modules/client';
import FormRadioGroup from '@/components/common/form-elements/FormRadioGroup';
import FormInputRadio from '@/components/common/form-elements/FormInputRadio';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout,
        FormInputRadio,
        FormRadioGroup
    },
    metaInfo: {
        title: 'Withdrawal Method'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            paymethod: '',
            email1: '',
            loading: false
            // email2: '',
        }
    },
    computed: {
        ...mapClientFields([
            'client_data.aff_paymethod',
            'client_data.aff_paypal_email',
            'client_data.email'
        ]),
        isPaypal() {
            return this.paymethod === 'paypal'
        },
        defaultEmail() {
            return this.isPaypal ? this.email1 : this.email
        }
    },
    watch: {
        aff_paymethod(value) {
            if (value === 'paypal') {
                this.email1 = ''
            }
        }
    },
    created() {
        this.paymethod = this.aff_paymethod || 'paypal'
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions({
            updatePaymethod: ACTION_CLIENT_UPDATE_PAYMETHOD
        }),
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                this.loading = true
                await this.updatePaymethod({
                    paymethod: this.paymethod,
                    email: this.defaultEmail
                })
                eventBus.$emit('showSnackBar', 'Your settings have been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'success');
            } finally {
                this.loading = false
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss">
    .setting_paymethod {
        &_radios{
            display: flex;
            &--mb{
                margin-bottom: 30px;
            }
            .checkout__payment{
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
        &_paypal-email{
            display: flex;
            justify-content: center;
            width: 50%;
            @media all and (max-width: 768px){
                width: 100%;
            }
        }
    }
</style>
